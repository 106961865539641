import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { UserService } from '@offconon/user-api';

import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private userService = inject(UserService);
  private router = inject(Router);
  private store = inject(Store);

  socialLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authSocialLogin),
      switchMap((action) =>
        this.userService.userSocialLoginCreate(action.auth).pipe(
          switchMap((user) =>
            of(AuthActions.loginSuccess({ loginResponse: user, returnUrl: action.returnUrl })),
          ),
          catchError((error) => of(AuthActions.loadAuthFailure({ error }))),
        ),
      ),
    ),
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authLogin),
      switchMap((action) =>
        this.userService.userLoginCreate(action.auth).pipe(
          switchMap((user) =>
            of(AuthActions.loginSuccess({ loginResponse: user, returnUrl: action.returnUrl })),
          ),

          catchError((error) => of(AuthActions.loadAuthFailure({ error }))),
        ),
      ),
    ),
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        map((action) => ({
          userEntity: AuthActions.setUserEntity(),
          returnUrl: action.returnUrl,
        })),
        tap(({ userEntity, returnUrl }) => {
          this.store.dispatch(userEntity);

          setTimeout(() => {
            if (returnUrl) window.location.pathname = returnUrl;
          }, 0);
        }),
      ),
    { dispatch: false },
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authRegister),
      concatMap((action) =>
        of(AuthActions.authSetLoadingState({ loading: true })).pipe(
          switchMap(() =>
            this.userService.userRegistrationCreate(action.auth).pipe(
              mergeMap(() =>
                of(
                  AuthActions.registerSuccess({ url: action.url }),
                  AuthActions.authSetLoadingState({ loading: false }),
                ),
              ),
              catchError((error) =>
                of(
                  AuthActions.loadAuthFailure({ error }),
                  AuthActions.authSetLoadingState({ loading: false }),
                ).pipe(),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerSuccess),
        tap((action) => this.router.navigate([action.url])),
      ),
    { dispatch: false },
  );
}
