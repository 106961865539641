<a
  tooltipPosition="bottom"
  class="flex p-2 md:p-3 lg:px-3 lg:py-2 items-center hover:bg-blue-500 hover:text-white font-medium cursor-pointer transition-duration-150 transition-colors dark:text-white p-button-rounded"
  [appendTo]="'body'"
  pTooltip="{{ 'Shopping cart' | translate }}"
  (click)="getShoppingCart()">
  <mat-icon class="text-base text-3xl" fontIcon="shopping_cart" />
  @if (new_count$ | async; as new_count_number) {
    @if (new_count_number > 0) {
      <div class="absolute">
        <div class="iconnotification text-center text-white">
          @if (new_count_number > 99) {
            <span class="minitext"> 99 <sup>+</sup> </span>
          } @else if (new_count_number <= 99 && new_count_number > 9) {
            <span class="notitext"> {{ new_count$ | async }}</span>
          } @else {
            <span class="lownotitext"> {{ new_count$ | async }}</span>
          }
        </div>
      </div>
    }
  }
</a>

<p-drawer
  styleClass="md:!w-1/2 lg:!w-1/4"
  [fullScreen]="false"
  [closeOnEscape]="true"
  [baseZIndex]="10000"
  [appendTo]="'body'"
  [styleClass]=""
  [showCloseIcon]="false"
  [position]="'right'"
  [(visible)]="shopping_cart_right_menu">
  <div class="flex items-center justify-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <div class="font-bold text-xl">
        {{ 'Shopping cart' | translate }}
      </div>
      <!--<div class="text-sm md:text-base mb-3 mt-2">
        {{ 'Add items and services your shopping cart from different sellers.' | translate }}
      </div>-->
    </div>
    <div class="">
      <p-button icon="pi pi-times" [link]="true" (click)="closeCart()" />
    </div>
  </div>
  <p-divider class="w-full" />

  @if (load_cart) {
    <offconon-shopping-cart-render-common [searchfilter]="false" />
  }
</p-drawer>
