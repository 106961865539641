@if (moduladditems) {
  <a
    tooltipPosition="bottom"
    class="flex p-2 md:p-3 lg:px-3 lg:py-2 items-center hover:bg-blue-500 hover:text-white font-medium cursor-pointer transition-duration-150 transition-colors"
    [appendTo]="'body'"
    pTooltip="{{ 'Create' | translate }}"
    (click)="addmenu.toggle($event)">
    <mat-icon class="text-primary text-2xl" fontIcon="library_add" />
  </a>

  <p-menu
    #addmenu
    styleClass="w-full md:w-96"
    [appendTo]="'body'"
    [popup]="true"
    [model]="moduladditems">
    <ng-template #start>
      <div class="mb-2">
        @if (isMobileView) {
          <div class="flex flex-row-reverse flex-wrap">
            <div class="flex items-center justify-center cursor-pointer">
              <mat-icon class="text-red-500" fontIcon="cancel" (click)="addmenu.hide()" />
            </div>
          </div>
        }
        <div class="mb-1 flex items-center justify-between">
          <div class="flex items-center mt-2 mb-2">
            <img src="/assets/images/offconon.png" height="50" class="m-2 w-8" />
            <div>
              <span class="block mb-1 text-sm white-space-nowrap"> OffConOn </span>
              <p class="m-0 text-xs text-primary">
                {{ module_name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #submenuheader let-item>
      <span class="text-teal-600 font-light">{{ item.label }}</span>
    </ng-template>
    <ng-template #item let-item>
      @if (item?.visible !== false) {
        <a class="flex items-center p-2 cursor-pointer mb-1 gap-2">
          <span class="inline-flex items-center justify-center text-4xl">
            <mat-icon
              class="{{ item?.icon_color ? item?.icon_color : 'text-primary' }} "
              fontIcon="{{ item.icon ? item.icon : 'add_circle' }}" />
          </span>
          <span class="inline-flex flex-col gap-1">
            <span class="font-light text-sm">{{ item.label }}</span>
          </span>
          @if (module_active === item?.link) {
            <span class="ml-auto bg-blue-500 text-xs p-1"> </span>
          }
        </a>
      }
    </ng-template>
  </p-menu>
}
