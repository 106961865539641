<!-- <offconon-core-layout></offconon-core-layout> -->
<offconon-layout [sideNavData]="sidenavData()">
  <ng-container left>
    @defer (when user()) {
      @if (user()) {
        <offconon-personal-business-switcher />
      }
    }
  </ng-container>
  <ng-container center>
    <!-- <offconon-layout-apps /> -->

    <offconon-layout-search />
    <!--- Language selectors start -->
    <offconon-language-change-selector
      [type]="'globe'"
      (openLanguageSelectorEvent)="openLang($event)" />
    <offconon-language-change-content
      [langChangeSidebar]="langChangeSidebar"
      (openLanguageSelectorEvent)="openLang($event)" />
    <!--- Language selectors end -->
  </ng-container>
  <ng-container rightSide>
    <offconon-core-right-panel (openLanguageSelectorEvent)="openLang($event)" />
    <offconon-layout-notifications *ngIf="showNotiCartIcon" />
    <offconon-layout-shopping-cart *ngIf="showNotiCartIcon" />
  </ng-container>
</offconon-layout>
