import { CommonModule } from '@angular/common';
import {
  afterNextRender,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DrawerModule } from 'primeng/drawer';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { LoginResponse } from '@offconon/core-api';
import { LanguageChangeSelectorComponent } from '@offconon/shared/features/language-change';
import { SharedUiThemeSwitchComponent, ThemeService } from '@offconon/shared/ui/theme-switch';

@Component({
  selector: 'offconon-start',
  imports: [
    DrawerModule,
    AvatarModule,
    ButtonModule,
    SharedUiThemeSwitchComponent,
    DividerModule,
    MatIcon,
    TranslateModule,
    TooltipModule,
    LanguageChangeSelectorComponent,
    MenuModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
  ],
  template: ` <div class="mb-2">
      @if (isMobileView$ | async) {
        <div class="flex flex-row-reverse flex-wrap">
          <div class="flex items-center justify-center cursor-pointer">
            <mat-icon class="text-red-500" fontIcon="cancel" (click)="hideMenu()" />
          </div>
        </div>
      }
      <div class="p-4 pb-0">
        <a class="cursor-pointer" [routerLink]="['/passport']">
          <span class="font-bold">
            {{ 'OffConOn Passport' | translate }}
          </span>
        </a>
      </div>
    </div>
    <div class="border-b-2 border-slate-200 pb-3">
      <div class="flex justify-around flex-wrap">
        <div class="flex items-center justify-center cursor-pointer" (click)="hideMenu()">
          @if (user) {
            <a
              class="flex p-3 lg:px-3 lg:py-2 items-center text-600 hover:bg-blue-500 hover:text-white font-medium p-button-rounded cursor-pointer transition-duration-150 transition-colors"
              [routerLink]="[redirectWorkplaceUrl]">
              <mat-icon
                tooltipPosition="bottom"
                class=" "
                fontIcon="qr_code"
                [appendTo]="'body'"
                pTooltip="{{ 'Workplaces' | translate }}" />
            </a>
          } @else {
            <a
              class="flex p-3 lg:px-3 lg:py-2 items-center text-600 hover:bg-blue-500 hover:text-white font-medium p-button-rounded cursor-pointer transition-duration-150 transition-colors"
              [routerLink]="['/login']">
              <mat-icon
                tooltipPosition="bottom"
                class=" "
                fontIcon="login"
                [appendTo]="'body'"
                pTooltip="{{ 'Login' | translate }}"
            /></a>
          }
        </div>
        <div class="flex items-center justify-center">
          <offconon-shared-ui-theme-switch (click)="hideMenu()" />
        </div>
        <div class="flex items-center justify-center">
          <offconon-language-change-selector [type]="'globe'" (click)="hideMenu(); openLang()" />
        </div>
      </div>
    </div>

    <div class="m-2">
      <!--<div class="mb-1 mt-4 ml-3 flex items-center justify-between cursor-pointer">
        @if (user) {
          <a class="flex items-center" [routerLink]="['/profile']" (click)="hideMenu()">
            @if (!user.avatar_path) {
              <offconon-monogram
                [avatarClass]="'ml-auto'"
                [options]="{ size: '3.2rem' }"
                [name]="user.full_name ? user.full_name : user.username" />
            }

            @if (user.avatar_path) {
              <p-avatar
                size="large"
                shape="circle"
                styleClass="ml-auto {{ user && (user.is_subscription ? '' : '') }}"
                image="{{ user.avatar_path }}" />
            }

            <div class="ml-1">
              <span class="flex items-center gap-1 text-sm font-medium white-space-nowrap">
                {{ user.full_name }}
                @if (user && user.is_verified) {
                  <span class="text-teal-500">
                    <mat-icon
                      style="height: initial; width: initial; font-size: 10px"
                      fontIcon="check_circle"
                      tooltipPosition="top"
                      [appendTo]="'body'"
                      pTooltip=" {{ 'Verified' | translate }}" />
                  </span>
                }
                @if (user && user.is_subscription) {
                <span class="text-blue-500">
                  <mat-icon
                    style="height: initial; width: initial; font-size: 10px"
                    fontIcon="diamond"
                    tooltipPosition="right"
                    [appendTo]="'body'"
                    pTooltip="{{ 'Premium member' | translate }}" />
                </span>
                }
              </span>
              <p class="m-0 text-xs text-primary">
                @if (user && user.id) {
                  &#64;
                }
                {{ user.username }}
              </p>
            </div>
          </a>
        }
      </div>-->

      @if (isUserEntity) {
        <div class="flex justify-center flex-wrap mt-2">
          <div
            class="flex items-center justify-center text-center leading-6 text-base md:text-lg text-red-500 m-2">
            {{ 'You are currently using this site as a business!' | translate }}
          </div>
        </div>
      }
    </div>`,
  styles: ``,
})
export class StartComponent implements OnChanges {
  @Input() user: LoginResponse | null = null;
  @Input() isUserEntity = false;
  @Output() openLanguageSelectorEvent = new EventEmitter<any>();
  @Output() hideMenuEvent = new EventEmitter<any>();
  private themeService = inject(ThemeService);
  isMobileView$: any;

  redirectWorkplaceUrl = '';
  constructor() {
    afterNextRender(() => {
      this.isMobileView$ = this.themeService.mobileView;
    });
  }
  ngOnChanges(): void {
    if (this.user) {
      this.redirectWorkplaceUrl = '/cognitive-erp/workplaces';
    } else {
      this.redirectWorkplaceUrl = '/login';
    }
  }
  hideMenu() {
    this.hideMenuEvent.emit();
  }

  openLang() {
    this.openLanguageSelectorEvent.emit(true);
    this.hideMenuEvent.emit();
  }
}
