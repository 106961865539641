import { Component, DestroyRef, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { HeaderMenuHelperService } from '@offconon/shared/utils/services';

@Component({
  imports: [MenuModule, TooltipModule, MatIcon, TranslateModule, DividerModule, ButtonModule],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .p-menu-list {
        max-height: calc(100vh - 300px);
        overflow: auto;
      }
    `,
  ],
  selector: 'offconon-core-add-options',
  templateUrl: './core-add-options.component.html',
})
export class CoreAddOptionsComponent implements OnInit {
  @Input() isMobileView = false;

  private translateService = inject(TranslateService);
  private headerMenuHelperService = inject(HeaderMenuHelperService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  moduladditems: MenuItem[];

  module_name: any; //todo async from store
  module_active: any;

  ngOnInit() {
    this.headerMenuHelperService.headerModuleAdd$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: any) => {
          if (data && data?.menuitems) {
            this.renderMenu(data);
          }
        },
      });
  }

  renderMenu(data: any) {
    this.moduladditems = [
      ...data.menuitems.map((main: any) => {
        return {
          label: main?.label,
          items: [
            ...main.items.map((item: any) => {
              return {
                link: item?.link,
                icon: item?.icon,
                label: this.translateService.instant(item?.label),
                visible: item?.visible ? item?.visible : true,
                command: () => {
                  if (item?.link) {
                    this.openSubPage(item?.link);
                  } else if (item?.commandname) {
                    this.commandSelector(item?.commandname);
                  }
                },
              };
            }),
          ],
        };
      }),
    ];

    this.module_name = this.translateService.instant(data?.modulename);
    this.module_active = data?.activepage;
  }

  commandSelector(command: any) {
    switch (command) {
      case 'dosomething': {
        //statements redirect to selected command;
        break;
      }
    }
  }

  openSubPage(url: any) {
    this.router.navigate([url]);
  }
}
