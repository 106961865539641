import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationGuard implements CanActivate {
  router = inject(Router);

  // Only define app URLs
  private readonly appUrls = [
    'botanix',
    'speakup',
    'bizconnect',
    'medspot',
    'beautywave',
    'petnest',
    'skill-link',
    'be-green',
    'second-chance',
    'eventix',
    'rentspace',
    'scheduly',
    'careerpath',
    'pixelmarket',
    'taskly',
    'market-space',
    'bucket-list',
    'travel-buddies',
    'collectors',
    'real-estate',
    'boat',
    'cars-wheels',
    'cooking',
    'fitness',
    'volunteer',
    'agriculture-livestock',
    'office-meeting',
    'kids',
    'dating',
    'team-up',
    'mindset',
    'grocery',
    'diy-handmade',
    'fashion',
    'tech',
    'crypto-blockchain',
    'b-to-b',
    'home-design',
    'media-channels',
    'digital-nomads',
    'gaming',
    'erotic',
    'roommate',
    'aviation',
    'taxi',
  ];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const appType = route.params['applicationType'];

    if (this.appUrls.includes(appType)) {
      return true;
    }

    const lang = route.parent?.params['lang'] || 'en';
    // Redirect to the 404 page with the correct language prefix
    this.router.navigate([`/${lang}/404`]);
    return false;
  }
}
