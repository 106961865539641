@if (user) {
  <a
    tooltipPosition="bottom"
    class="relative flex p-2 md:p-3 lg:px-3 lg:py-2 items-center hover:bg-blue-500 hover:text-white font-medium cursor-pointer transition-duration-150 transition-colors dark:text-white p-button-rounded"
    [appendTo]="'body'"
    pTooltip="{{ 'Notifications' | translate }}"
    (click)="getNotification()">
    <mat-icon class="text-3xl" fontIcon="notifications" />

    @if (new_count$ | async; as new_count_number) {
      @if (new_count_number > 0) {
        <div class="absolute">
          <div class="iconnotification text-center text-white">
            @if (new_count_number > 99) {
              <span class="minitext"> 99 <sup>+</sup> </span>
            } @else if (new_count_number <= 99 && new_count_number > 9) {
              <span class="notitext"> {{ new_count$ | async }}</span>
            } @else {
              <span class="lownotitext"> {{ new_count$ | async }}</span>
            }
          </div>
        </div>
      }
    }
  </a>
}

<p-drawer
  #notificationRightMenu
  position="right"
  styleClass="md:!w-1/2 lg:!w-1/3"
  [fullScreen]="isMobileView ? true : false"
  [baseZIndex]="99"
  [appendTo]="'body'"
  [modal]="isMobileView ? false : true"
  [closeOnEscape]="true"
  [showCloseIcon]="false"
  [(visible)]="notification_right_menu">
  <div class="flex items-center justify-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <div class="font-bold mb-1 text-xl">
        {{ 'Notifications' | translate }}
      </div>
      <!--<div class="text-base lg:text-lg mb-3 mt-2">
        {{ user ? user.full_name : ('Guest' | translate) }}
      </div>-->
    </div>
    <div class="mt-2 lg:mt-0 flex flex-wrap lg:flex-nowrap">
      <p-button icon="pi pi-times" [link]="true" (click)="hideNotificationRightMenu()" />
    </div>
  </div>

  <div>
    @if (notifications && notifications.length !== 0) {
      <div class="flex flex-col">
        @for (notification of notifications; track notification) {
          <div class="flex flex-col gap-2 mb-2 w-full">
            <offconon-notification-common-panel
              [system_data]="notification"
              [collapsedStatus]="collapsedStatus"
              [truncate_number]="truncate_number"
              [isMobileView]="isMobileView" />
          </div>
        }
      </div>
    }
    @if (!notifications) {
      <offconon-panel-skeleton [panel_css]="'w-full'" />
    }
    @if (notifications && notifications.length === 0) {
      <div class="text-center">
        <div class="text-red-500 text-lg m-2 pb-3 pt-3">
          {{ 'You have no notifications!' | translate }}
        </div>
      </div>
    }
  </div>
  @if (notifications && notifications.length !== 0) {
    <div class="pt-3 pb-2 mb-2 ml-2 mr-2 flex flex-wrap gap-3 justify-between">
      <p-button
        icon="pi pi-arrow-right"
        iconPos="right"
        [styleClass]="'w-full'"
        label="{{ 'View all' | translate }}"
        [rounded]="true"
        (click)="clickViewAll()" />

      <p-button
        icon="pi pi-trash"
        iconPos="left"
        severity="danger"
        [styleClass]="'w-full'"
        label="{{ 'Delete all' | translate }}"
        [rounded]="true"
        (click)="confirmDelete()" />
    </div>
  }
  <!--  to do fix if primeng fixed
  <ng-template  #footer >
    @if (notifications && notifications.length !== 0) {
      <p-divider class="w-full" />
      <div class="pt-3 pb-2 mb-2 ml-2 mr-2 flex flex-wrap gap-3 justify-evenly">
        <p-button
          icon="pi pi-arrow-right"
          iconPos="right"
          [styleClass]="'w-full'"
          label="{{ 'View all' | translate }}"
          [rounded]="true"
          [outlined]="true"
          (click)="clickViewAll()" />

        <p-button
          icon="pi pi-trash"
          iconPos="left"
          severity="danger"
          [styleClass]="'w-full'"
          label="{{ 'Delete all' | translate }}"
          [rounded]="true"
          [outlined]="true"
          (click)="confirmDelete()" />
      </div>
    }
  </ng-template>-->
</p-drawer>

<p-confirmDialog
  key="notificationRightDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="2"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
