@defer (when userBusinessEntity()) {
  <div
    class="relative flex items-center gap-2 pr-2 rounded-full cursor-pointer header-profile"
    (click)="rightmenu.toggle($event)">
    @if (userBusinessEntity()?.avatar_path) {
      <p-avatar
        shape="circle"
        [image]="userBusinessEntity()?.avatar_path"
        [size]="(themeService.mobileView | async) ? 'normal' : 'large'"
        [styleClass]="'border-2 border-gray-200/10  w-8 h-8 md:w-12 md:h-12 '" />
    } @else {
      <offconon-monogram
        [avatarClass]="'w-10 h-10'"
        [options]="{ size: '2.5rem' }"
        [name]="user?.full_name || 'Guest'" />
    }

    @if (userBusinessEntity()) {
      <div class="text-left hidden md:flex flex-col">
        <span class="text-sm font-medium">{{ userBusinessEntity()?.name }}</span>
        <span class="text-xs text-blue-400">{{
          userBusinessEntity()?.label || '' | translate
        }}</span>
      </div>
    } @else {
      <div class="text-left hidden md:flex flex-col">
        <span class="text-sm font-medium dark:text-gray-300">{{ 'Guest' | translate }}</span>
      </div>
    }
    <div class="hidden sm:block">
      <i class="pi pi-angle-down text-gray-400 ml-1"></i>
    </div>
  </div>
} @placeholder {
  <div class="relative flex items-center gap-2 pr-2 rounded-full cursor-pointer header-profile">
    <offconon-monogram
      [avatarClass]="'w-10 h-10'"
      [options]="{ size: '2.5rem' }"
      [name]="user?.full_name || 'Guest'" />
    <div class="text-left hidden md:flex flex-col">
      <span class="text-sm font-medium">{{ 'Guest' | translate }}</span>
    </div>
    <div class="hidden sm:block">
      <i class="pi pi-angle-down text-gray-400 ml-1"></i>
    </div>
  </div>
}
<p-menu
  #rightmenu
  styleClass="md:w-96 md:mr-5"
  [id]="'offconon-layout__rightmenu'"
  [appendTo]="'body'"
  [popup]="true"
  [model]="rightItems">
  <ng-template #start>
    <offconon-start
      [isUserEntity]="isUserEntity"
      [user]="user"
      (openLanguageSelectorEvent)="openLang($event)"
      (hideMenuEvent)="rightmenu.hide()" />
  </ng-template>
  <ng-template #submenuheader let-item>
    <div class="font-extrabold text-lg">{{ item.label }}</div>
  </ng-template>
  <ng-template #item let-item>
    <a class="flex items-center gap-2 p-menuitem-link mt-1 ml-4" [class.hidden]="!item?.visible">
      <span class="inline-flex items-center justify-center text-primary">
        <mat-icon
          class="{{ item?.text_color ? item?.text_color : 'text-primary' }}"
          fontIcon="{{ item.icon ? item.icon : 'add_circle' }}" />
      </span>
      <span
        class="flex-col gap-2 inline-flex opacity-60 text-sm {{
          item?.text_color ? item?.text_color : ''
        }} "
        >{{ item.label }}</span
      >
      <span [ngClass]="item?.selector ? 'bg-blue-500 ml-auto  text-white text-xs p-1' : ''">
        {{ item?.selector }}
      </span>
    </a>
  </ng-template>
  <ng-template #end>
    <div class="m-4">
      <div class="font-extrabold text-lg mb-1 mt-4">{{ 'Quick links' | translate }}</div>
      <a class="flex items-center gap-2 p-menuitem-link mt-1" [routerLink]="['/apps']">
        <span class="inline-flex items-center justify-center text-primary"
          ><mat-icon fontIcon="grid_view"
        /></span>
        <span class="flex-col gap-2 inline-flex opacity-60 text-sm">{{
          'All modules' | translate
        }}</span>
      </a>
    </div>
  </ng-template>
</p-menu>
