import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DrawerModule } from 'primeng/drawer';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject } from 'rxjs';

import { getIndicators, selectIndicators } from '@offconon/shared/features/layout';
import { ShoppingCartRenderCommonComponent } from '@offconon/shared/ui/shopping-cart-render-shared-common';
import { ShoppingCartService as CartHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-layout-shopping-cart',
  imports: [
    DrawerModule,
    TooltipModule,
    DividerModule,
    ButtonModule,
    MatIcon,
    TranslateModule,
    ShoppingCartRenderCommonComponent,
    AsyncPipe,
    CommonModule,
  ],
  templateUrl: './layout-shopping-cart.component.html',
})
export class LayoutShoppingCartComponent implements OnInit {
  private cart_items_count: any = 0;
  private store$ = inject(Store);
  shopping_cart_right_menu: any;
  load_cart = false;
  private cartHelperService = inject(CartHelperService);

  new_count$ = new BehaviorSubject<number>(5);

  ngOnInit() {
    this.cartHelperService.shoppingcartPanel$.subscribe({
      next: (item_data) => {
        if (item_data) {
          this.closeCart();
          this.cartHelperService.closeShoppingCartPanel(false);
        }
      },
    });
    this.store$.dispatch(getIndicators());
    this.store$.select(selectIndicators).subscribe((counts) => {
      this.cart_items_count = counts?.userCounts?.cart_items_count;
    });
    this.new_count$.next(this.cart_items_count);
  }
  getShoppingCart() {
    this.new_count$.next(this.cart_items_count);
    this.shopping_cart_right_menu = true;
    this.load_cart = true;
  }

  closeCart() {
    this.shopping_cart_right_menu = false;
    this.load_cart = false;
  }
}
