import { importProvidersFrom, inject } from '@angular/core';
import { Route } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  SEARCH_SETTINGS_FEATURE_KEY,
  SearchSettingsEffects,
  searchSettingsReducer,
} from 'libs/core/data/stores/search-settings';
import { AuthEffects } from 'libs/shared/data/stores/auth/auth.effects';
import { AUTH_FEATURE_KEY, authReducer } from 'libs/shared/data/stores/auth/auth.reducer';
import { CHAT_FEATURE_KEY, ChatEffects, chatReducer } from 'libs/shared/data/stores/chat';
import { PaymentEffects } from 'libs/shared/data/stores/shared-data-access-payment/src/lib/store/payment.effects';
import { ApplicationGuard } from 'libs/shared/utils/guards/application.guard';
import { UrlGuard } from 'libs/shared/utils/guards/url.guard';

import { LoginActiveGuard, UserEntityGuard } from '@offconon/core/features/auth';
import {
  WALLET_FEATURE_KEY,
  walletReducer,
  WalletEffects,
} from '@offconon/shared/data/store/wallet';
import {
  SEARCH_FEATURE_KEY,
  SearchEffects,
  searchReducer,
} from '@offconon/shared/data/stores/search';
import {
  PAYMENT_FEATURE_KEY,
  paymentReducer,
} from '@offconon/shared/data/stores/shared-data-access-payment';
import { USER_FEATURE_KEY, userReducer, UserEffects } from '@offconon/shared/data/stores/user';
import {
  MEDIA_GALLERY_FEATURE_KEY,
  MediaGalleryEffects,
  mediaGalleryReducer,
} from '@offconon/shared/features/media-gallery';
import {
  TASK_FEATURE_KEY,
  TaskEffects,
  taskReducer,
} from '@offconon/shared/stores/shared-data-access-task';
import {
  LoadLocaleDataService,
  loadTranslate,
  resolveTranslate,
} from '@offconon/shared/utils/translate';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'en',
    pathMatch: 'full',
  },
  {
    path: ':lang',
    canActivate: [UrlGuard],
    resolve: { data: () => inject(LoadLocaleDataService).loadData() },

    children: [
      {
        path: '',
        loadChildren: () =>
          import('@offconon/core/features/search').then((m) => m.CoreFeaturesSearchRoutes),
      },

      {
        path: 'ads',
        loadChildren: () =>
          import('@offconon/core/features/ads-module/ads-routing').then((m) => m.adsRoutingRoutes),
      },

      {
        path: 'offers-discounts-coupons',
        loadChildren: () =>
          import('@offconon/core/features/offer-discount-module/offers-discount-routing').then(
            (m) => m.offersDiscountRoutingRoutes,
          ),
      },

      {
        path: 'ad-details',
        loadChildren: () =>
          import('@offconon/core/features/ad-details').then((m) => m.AdDetailsRoutes),
      },

      {
        path: 'test',

        loadComponent: () =>
          import('@offconon/shared/features/payment/paypal').then((m) => m.PaypalComponent),
      },
      {
        path: 'personal-subscription',
        redirectTo: 'subscription/personal-subscription',
      },
      {
        path: 'personal-subscription/checkout',
        redirectTo: 'subscription/personal-subscription/checkout',
      },

      {
        path: 'my-subscription',
        loadChildren: () =>
          import('@offconon/core/features/my-subscription').then(
            (m) => m.CoreFeaturesMySubscriptionRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'social-connections',
        loadChildren: () =>
          import('@offconon/core/features/social-connection-module/user-social-connections').then(
            (m) => m.userSocialConnectionsRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'traditional-bank-transfer',
        loadChildren: () =>
          import('@offconon/core/features/traditional-bank-transfer').then(
            (m) => m.TraditionalBankTransferRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'invoices',
        loadChildren: () =>
          import('@offconon/core/features/invoices').then((m) => m.coreFeaturesInvoicesRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'communication',
        loadChildren: () =>
          import('@offconon/core/features/communication').then(
            (m) => m.CoreFeaturesCommunicationRoutes,
          ),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(CHAT_FEATURE_KEY, chatReducer),
            StoreModule.forFeature(USER_FEATURE_KEY, userReducer),
            EffectsModule.forFeature(ChatEffects),
            EffectsModule.forFeature(UserEffects),
          ),
        ],

        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'privacy-policy',
        loadComponent: () =>
          import('@offconon/core/features/privacy').then((m) => m.PrivacyPolicyPageComponent),
      },
      {
        path: 'subscription',
        providers: [
          importProvidersFrom(
            EffectsModule.forFeature(PaymentEffects),
            StoreModule.forFeature(PAYMENT_FEATURE_KEY, paymentReducer),

            StoreModule.forFeature(WALLET_FEATURE_KEY, walletReducer),
            EffectsModule.forFeature(WalletEffects),
          ),
        ],
        loadChildren: () =>
          import('@offconon/core/features/subscription').then(
            (m) => m.coreFeatureSubscriptionRoutes,
          ),
      },

      {
        path: 'calendar',
        loadChildren: () =>
          import('@offconon/core/features/calendar-module/user-calendar-routing').then(
            (m) => m.userCalendarRoutingRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'partner-office',
        loadChildren: () =>
          import('@offconon/core/features/partner-office-user').then(
            (m) => m.partnerOfficeUserRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'email-manager',
        loadChildren: () =>
          import('@offconon/core/features/email-manager-user').then(
            (m) => m.emailManagerUserRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'notifications',
        loadChildren: () =>
          import('@offconon/core/features/notification-module/notification-core-routing').then(
            (m) => m.NotificationCoreRoutingComponent,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'academy',
        loadChildren: () =>
          import('@offconon/core/features/academy-module/academy-core-routing').then(
            (m) => m.academyCoreRoutingRoutes,
          ),
      },

      {
        path: 'businesses-stores',
        loadChildren: () =>
          import('@offconon/core/features/businesses-stores').then(
            (m) => m.CoreFeaturesBusinessesStoresRoutes,
          ),
      },

      {
        path: 'activity-stream',
        loadChildren: () =>
          import('@offconon/core/features/activity-stream-module/activity-stream-routing').then(
            (m) => m.activityStreamRoutingRoutes,
          ),
        canActivate: [LoginActiveGuard],
      },

      {
        path: 'apps',
        loadChildren: () =>
          import('@offconon/core/features/apps').then((m) => m.CoreFeaturesAppsRoutes),
      },

      {
        path: 'task-manager',
        loadChildren: () =>
          import('@offconon/core/features/task-manager').then(
            (m) => m.CoreFeaturesTaskManagerRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(SEARCH_FEATURE_KEY, searchReducer),
            StoreModule.forFeature(TASK_FEATURE_KEY, taskReducer),
            StoreModule.forFeature(MEDIA_GALLERY_FEATURE_KEY, mediaGalleryReducer),
            EffectsModule.forFeature(SearchEffects, MediaGalleryEffects, TaskEffects),
          ),
        ],
      },

      {
        path: 'contacts',
        loadChildren: () =>
          import('@offconon/core/features/contacts').then((m) => m.CoreFeaturesContactsRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'goal-manager',
        loadChildren: () =>
          import('@offconon/core/features/goal-manager').then(
            (m) => m.CoreFeaturesGoalManagerRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'drive',
        loadChildren: () =>
          import('@offconon/core/features/drive').then((m) => m.CoreFeaturesDriveRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'loyalty',
        loadChildren: () =>
          import('@offconon/core/features/loyalty').then((m) => m.CoreFeaturesLoyaltyRoutes),
      },

      {
        path: 'passport',
        loadChildren: () =>
          import('@offconon/core/features/passport').then((m) => m.CoreFeaturesPassportRoutes),
      },

      {
        path: 'shopping-cart',
        loadChildren: () =>
          import('@offconon/core/features/shopping-cart').then(
            (m) => m.CoreFeaturesShoppingCartRoutes,
          ),
      },

      {
        path: 'my-purchases',
        loadChildren: () =>
          import('@offconon/core/features/my-purchases').then(
            (m) => m.CoreFeaturesMyPurchasesRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'purchase-ratings',
        loadChildren: () =>
          import('@offconon/core/features/purchase-ratings').then(
            (m) => m.CoreFeaturesPurchaseRatingsRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'business-access-denied',
        loadChildren: () =>
          import('@offconon/core/features/business-access-denied').then(
            (m) => m.CoreFeaturesBusinessAccessDeniedRoutes,
          ),
        canActivate: [LoginActiveGuard],
      },

      {
        path: 'my-auctions',
        loadChildren: () =>
          import('@offconon/core/features/my-auctions').then((m) => m.CoreFeaturesMyAuctionsRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'my-offers',
        loadChildren: () =>
          import('@offconon/core/features/my-offers').then((m) => m.CoreFeaturesMyOffersRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'favourites',
        loadChildren: () =>
          import('@offconon/core/features/favourites').then((m) => m.CoreFeaturesFavoritesRoutes),
      },

      {
        path: 'recently-viewed-ads',
        loadChildren: () =>
          import('@offconon/core/features/recently-viewed-ads').then(
            (m) => m.CoreFeaturesRecentlyViewedAdsRoutes,
          ),
      },

      {
        path: 'verification',
        loadChildren: () =>
          import('@offconon/core/features/verification').then(
            (m) => m.CoreFeaturesVerificationRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'blocked-users',
        loadChildren: () =>
          import('@offconon/core/features/blocked-users').then(
            (m) => m.CoreFeaturesBlockedUsersRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'my-apps',
        loadChildren: () =>
          import('@offconon/core/features/my-apps').then((m) => m.CoreFeaturesMyAppsRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'checkout',
        loadChildren: () =>
          import('@offconon/core/features/checkout').then((m) => m.CoreFeaturesCheckoutRoutes),
      },

      {
        path: 'cognitive-erp',
        loadChildren: () =>
          import('@offconon/core/features/cognitive-erp').then(
            (m) => m.CoreFeaturesCognitiveErpRoutes,
          ),

        resolve: { data: () => inject(LoadLocaleDataService).loadData() },
      },

      {
        path: 'create-ad',
        loadChildren: () =>
          import('@offconon/core/features/create-ad').then((m) => m.CreateAdRoutes),
      },

      {
        path: '404',

        loadComponent: () =>
          import('@offconon/shared/ui/page-not-found').then((m) => m.PageNotFoundComponent),
      },

      {
        path: 'terms',
        loadChildren: () =>
          import('@offconon/core/features/terms').then((m) => m.coreFeaturesTermsRoutes),
      },

      {
        path: 'auth',
        loadChildren: () =>
          import('@offconon/core/features/auth').then((m) => m.CoreFeaturesAuthRoutes),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
            EffectsModule.forFeature(AuthEffects),
          ),
        ],
      },
      {
        path: 'login',
        redirectTo: 'auth/login',
      },
      {
        path: 'registration',
        redirectTo: 'auth/registration',
      },

      {
        path: 'reset-password',
        redirectTo: 'auth/reset-password',
      },
      {
        path: 'resend-email',
        redirectTo: 'auth/resend-email',
      },
      {
        path: 'verify-email',
        redirectTo: 'auth/verify-email',
      },

      {
        path: 'me',
        loadChildren: () =>
          import('@offconon/core/features/user-profile-settings').then(
            (m) => m.userProfileSettingsRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(SEARCH_SETTINGS_FEATURE_KEY, searchSettingsReducer),
            EffectsModule.forFeature(SearchSettingsEffects),
          ),
        ],
      },

      {
        path: 'profile',
        loadChildren: () =>
          import('@offconon/core/features/user-profile-public').then(
            (m) => m.UserProfilePublicRoutes,
          ),
        canActivate: [LoginActiveGuard],
      },

      {
        path: 'business-profile',
        loadChildren: () =>
          import('@offconon/core/features/business-profile').then(
            (m) => m.CoreFeaturesBusinessProfileRoutes,
          ),
      },

      {
        path: 'page-not-found',
        loadComponent: () =>
          import('@offconon/shared/ui/page-not-found').then((m) => m.PageNotFoundComponent),
      },

      {
        path: 'wallet',
        loadChildren: () =>
          import(
            '@offconon/core/features/financial-management-module/wallet-module/wallet-routing'
          ).then((m) => m.walletRoutingRoutes),
        canActivate: [LoginActiveGuard, UserEntityGuard],
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(WALLET_FEATURE_KEY, walletReducer),
            EffectsModule.forFeature(WalletEffects),
          ),
        ],
        resolve: { data: () => inject(LoadLocaleDataService).loadData() },
      },
      {
        path: 'media',
        loadChildren: () =>
          import('@offconon/core/features/media-module/media-routing').then(
            (m) => m.mediaRoutingRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'notes',
        loadChildren: () =>
          import('@offconon/core/features/note-module/user-notes-routing').then(
            (m) => m.userNotesRoutingRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
      },

      {
        path: 'address-book',
        loadChildren: () =>
          import('@offconon/core/features/address-book').then(
            (m) => m.CoreFeaturesAddressBookRoutes,
          ),
        canActivate: [LoginActiveGuard, UserEntityGuard],
        providers: [importProvidersFrom(loadTranslate('./assets/translate/i18n/country/'))],
        resolve: {
          lang: () => {
            resolveTranslate();
          },
        },
      },

      {
        path: 'search-settings',
        loadChildren: () =>
          import('@offconon/core/features/search-settings').then(
            (m) => m.CoreFeaturesSearchSettingsRoutes,
          ),
      },

      {
        path: 'contact-us',
        loadChildren: () =>
          import('@offconon/core/features/contact-us').then((m) => m.CoreFeaturesContactUsRoutes),
      },

      {
        path: 'erp-auth/:host',
        loadComponent: () => import('@offconon/core/features/auth').then((m) => m.ErpAuthComponent),
        canActivate: [LoginActiveGuard],
      },
      {
        path: ':applicationType',
        canActivate: [ApplicationGuard],
        loadChildren: () =>
          import('@offconon/core/features/app-common-module/app-common-routing').then(
            (m) => m.appCommonRoutingRoutes,
          ),
      },
      {
        path: '404',
        loadComponent: () =>
          import('@offconon/shared/ui/page-not-found').then((m) => m.PageNotFoundComponent),
      },
      {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'en',
  },
];
