import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getAuthUser } from 'libs/shared/data/stores/auth/auth.selectors';
import { EventBusService } from 'libs/shared/features/layout/layout/src/lib/layout/data/services/event-bus-service.service';
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { Drawer, DrawerModule } from 'primeng/drawer';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
  LoginResponse,
  NotificationsService,
  NotificationSystem,
  PaginatedNotificationSystemList,
} from '@offconon/core-api';
import { getIndicators, selectIndicators } from '@offconon/shared/features/layout';
import { NotificationCommonPanelComponent } from '@offconon/shared/ui/notification-module/notification-common-panel';
import { PanelSkeletonComponent } from '@offconon/shared/ui/skeleton-render';
import { ThemeService } from '@offconon/shared/ui/theme-switch';
import { NotificationHelperService } from '@offconon/shared/utils/services';
import { UserService } from '@offconon/user-api';

@Component({
  selector: 'offconon-layout-notifications',
  imports: [
    DrawerModule,
    MatIcon,
    TranslateModule,
    TooltipModule,
    ButtonModule,
    DividerModule,
    ConfirmDialogModule,
    PanelSkeletonComponent,
    NotificationCommonPanelComponent,
    AsyncPipe,
    CommonModule,
  ],
  templateUrl: './layout-notifications.component.html',
  providers: [ConfirmationService],
})
export class LayoutNotificationsComponent implements OnInit, OnDestroy {
  private themeService = inject(ThemeService);
  private store$ = inject(Store);
  private notificationsService = inject(NotificationsService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private confirmationService = inject(ConfirmationService);

  @ViewChild('notificationRightMenu') notificationRightMenu: Drawer;
  private eventSubscription: Subscription | undefined;

  isMobileView = false;
  truncate_number = 35;
  collapsedStatus = true;
  notification_right_menu: any;

  private destroyRef = inject(DestroyRef);
  private eventBusService = inject(EventBusService);

  notifications: NotificationSystem[] | undefined;
  result: any;
  isloaded = false;

  new_count$ = new BehaviorSubject<number>(12);

  public user?: LoginResponse;

  private notificactionHelperService = inject(NotificationHelperService);
  private userService = inject(UserService);
  private unread_notifications: any = 0;

  constructor() {
    this.notificactionHelperService.notificationPanel$.subscribe({
      next: (data: any) => {
        this.notification_right_menu = data;
      },
    });
    this.notificactionHelperService.notificationUpdated.subscribe({
      next: (newState) => {
        this.getNotifications();
      },
    });
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.eventSubscription = this.eventBusService.event$.subscribe((event) => {
      if (event && event.action === 'markRead') {
        this.store$.dispatch(getIndicators());
        this.store$.select(selectIndicators).subscribe((counts) => {
          this.unread_notifications = counts?.userCounts?.unread_notifications;
          this.new_count$.next(this.unread_notifications);
        });
      }
    });
    this.store$.select(getAuthUser).subscribe((user) => {
      this.user = user;
    });
    this.themeService.mobileView.subscribe((isMobileView) => {
      this.isMobileView = isMobileView;
    });
    if (this.isMobileView) {
      this.truncate_number = 15;
    }
    if (this.isMobileView) {
      this.truncate_number = 15;
    }
    this.store$.dispatch(getIndicators());
    this.store$.select(selectIndicators).subscribe((counts) => {
      this.unread_notifications = counts?.userCounts?.unread_notifications;
    });
    this.new_count$.next(this.unread_notifications);
    this.getNotifications();
  }

  getNotifications(page = 1, page_size = 10) {
    this.notificationsService
      .notificationsList(page, page_size, {
        receiver_user_id: this.user?.id,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: PaginatedNotificationSystemList) => {
          this.notifications = result.results;
          this.result = result;
          this.new_count$.next(this.unread_notifications);
        },
        error: () => {},
        complete: () => {
          this.isloaded = true;
        },
      });
  }
  refreshComponent(data: any) {
    if (data) {
      this.store$.dispatch(getIndicators());
      this.store$.select(selectIndicators).subscribe((counts) => {
        this.unread_notifications = counts?.userCounts?.unread_notifications;
        this.new_count$.next(this.unread_notifications);
      });
    }
  }

  setNotifications() {
    this.notificactionHelperService.notificationsListObservable.subscribe({
      next: () => {
        this.getNotifications();
      },
    });
  }

  getNotification() {
    this.setNotifications();
    this.notification_right_menu = true;
  }

  clickViewAll() {
    this.notification_right_menu = false;
    this.notificationRightMenu.hide();
    this.router.navigate(['/notifications']);
  }

  confirmDelete() {
    this.notification_right_menu = false;
    this.confirmationService.confirm({
      message:
        this.translateService.instant('Are you sure you want to delete this?') +
        ' ' +
        this.translateService.instant('This decision can not be reversed!'),
      header: this.translateService.instant('Delete'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notificationsService
          .notificationsDeleteAllDestroy()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'info',
                summary: this.translateService.instant('Delete'),
                detail: this.translateService.instant('The item has been deleted!'),
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('Access denied'),
                detail: this.translateService.instant(
                  'We cannot delete this because it has an active connection with other modules.',
                ),
              });
            },
            complete: () => {
              this.notificactionHelperService.setNotificationsList();
            },
          });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: this.translateService.instant('Cancel'),
              detail: this.translateService.instant('This item has remained active!'),
            });
            break;
        }
      },
      key: 'notificationRightDialog',
    });
  }

  hideNotificationRightMenu() {
    this.notification_right_menu = false;
    this.notificationRightMenu.hide();
  }
}
