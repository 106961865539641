import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, afterNextRender, computed, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAuthUser } from 'libs/shared/data/stores/auth/auth.selectors';
import { PrimeNG } from 'primeng/config';
import { tap } from 'rxjs';

import { AuthService } from '@offconon/core/features/auth';
import { PersonalBusinessSwitcherComponent } from '@offconon/core/features/personal-business-switcher';
import { CoreRightPanelComponent } from '@offconon/features/core-layout';
import {
  LanguageChangeContentComponent,
  LanguageChangeSelectorComponent,
} from '@offconon/shared/features/language-change';
import { LayoutComponent } from '@offconon/shared/features/layout';
import { LayoutNotificationsComponent } from '@offconon/shared/features/layout/layout-notifications';
import { LayoutSearchComponent } from '@offconon/shared/features/layout/layout-search';
import { LayoutShoppingCartComponent } from '@offconon/shared/features/layout/layout-shopping-cart';
import { ThemeService, theme } from '@offconon/shared/ui/theme-switch';
import { WebSocketService } from '@offconon/shared/utils/services';

import { environment } from '../environments/environment';
import { SideBarService } from './services/side-bar.service';

@Component({
  selector: 'offconon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    LayoutComponent,
    CoreRightPanelComponent,
    LayoutSearchComponent,
    LayoutNotificationsComponent,
    LayoutShoppingCartComponent,
    PersonalBusinessSwitcherComponent,
    LanguageChangeContentComponent,
    LanguageChangeSelectorComponent,
  ],
})
export class AppComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private authenticationService = inject(AuthService);
  private router = inject(Router);
  private themeService = inject(ThemeService);
  private primengConfig = inject(PrimeNG);
  sideBarService = inject(SideBarService);
  private store = inject(Store);

  isConnected = false;

  webSocketService = inject(WebSocketService);
  showNotiCartIcon = false;
  user = toSignal(
    this.store.select(getAuthUser).pipe(
      tap(() => {
        this.checkIfNavigationNeeds();
      }),
    ),
  );
  isMobileView = false;
  sideNav: any;
  sidenavData = computed(() => this.sideBarService.menus());
  langChangeSidebar = false;
  constructor() {
    afterNextRender(() => {
      this.themeService.mobileView
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((isMobileView) => {
          this.isMobileView = isMobileView;
        });

      this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.checkIfNavigationNeeds();
        }
      });

      this.authenticationService.checkClientId();

      this.webSocketService.connect(environment.coreWebsocket);
    });
  }

  ngOnInit(): void {
    if (this.authenticationService.currentUserValue?.id) {
      this.showNotiCartIcon = true;
    }
    this.authenticationService.handleExpiredToken();

    this.primengConfig.theme.set(theme);
  }
  openLang(event: any) {
    this.langChangeSidebar = event;
  }

  checkIfNavigationNeeds() {
    if (this.user && this.user()?.action_method === 'PasswordReset') {
      this.router.navigate(['me', 'password-change']);
    }
  }
}
